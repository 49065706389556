import { AxiosAPIError } from './types';

const RECAPTCHA_BELOW_THRESHOLD = 'EVO_BUS_P354';

type AxiosErrorWithReCaptchaResponse = AxiosAPIError & {
  response: {
    data: {
      items: [
        {
          recaptchaStatus: boolean;
          recaptchaScore: number;
        },
      ];
    };
  };
};

export const isReCaptchaThresholdError = (
  error: AxiosAPIError
): error is AxiosErrorWithReCaptchaResponse =>
  error.response?.status === 412 &&
  error.response?.data?.Code === RECAPTCHA_BELOW_THRESHOLD;
