import isEqual from 'lodash/isEqual';

// For each property, where the user has made a local change we should use that.
// Otherwise we use the value in the session if it exists. (We fallback to the local
// value where it's undefined in the session for backwards compatibility.)
export const mergeFormData = <T>(localData: T, sessionData: T, initialData: T): T => {
  if (!sessionData) {
    return localData;
  }
  const mergedDataArray =
    localData &&
    Object.entries(localData).map(([key, localValue]) => {
      return [
        key,
        isEqual(localValue, initialData[key as keyof T])
          ? (sessionData[key as keyof T] ?? localValue)
          : localValue,
      ];
    });

  return Object.fromEntries(mergedDataArray) as T;
};
