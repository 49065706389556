import {
  addDaysToDate,
  dateValueToUtcDate,
  localDateToUtcDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import {
  applyRuleIf,
  dateValueLessThanOrEqualTo,
  dateValueValid,
  dateValueValidDay,
  dateValueValidMonth,
  dateValueValidYear,
  required,
  requiredDateValue,
  validateIf,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import {
  ValidationErrorAndWarningRules,
  ValidationRule,
} from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { assumptionsIncludeId } from 'businessLogic/aggregatorAssumptions';
import useAssumptions from 'components/CheckYourDetails/AggregatorAssumptionsSection/assumptions';
import { isOlderThanXYearsOld } from 'helpers/ageHelpers';
import { petNeuteredOrSpayedReplacer } from 'helpers/placeholders/aboutYourPetplaceholders';
import {
  catBreedType_NON_PEDIGREE,
  catBreedType_PEDIGREE,
  dogBreedType_CROSS_BREED,
  dogBreedType_MONGREL,
  dogBreedType_PEDIGREE,
  gender_F,
  gender_M,
  petType_CAT,
  petType_DOG,
} from 'helpers/referenceDataConstants';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useDisableDateChecks from 'helpers/useDisableDateChecks';
import useKickoutMessageText from 'helpers/useKickoutMessageText';
import { useAssumptionsAgreement } from 'state/formData/assumptionsAgreement';
import { Pet, PetsDetails } from 'state/formData/petsDetails';
import { CsErrorsMissingOnly } from 'types/contentStack';
import {
  showEligibilityQuestions as eligibilityQuestionsDisplayed,
  showGeneralPetQuestions as generalPetQuestionsDisplayed,
  showPetGoodHealthQuestions as goodHealthQuestionDisplayed,
  showPetBreedQuestions as petBreedQuestionsDisplayed,
} from './gradualRevealHelpers';

type CsAboutYourPetErrorMessages = {
  csPetAboutYourPetMainQuestionsV2: {
    pet_name: CsErrorsMissingOnly;
    pet_type: CsErrorsMissingOnly;
    pet_gender: CsErrorsMissingOnly;
    pet_cost: CsErrorsMissingOnly;
    pet_spayed: CsErrorsMissingOnly;
    pet_chipped: CsErrorsMissingOnly;
    pet_in_good_health: CsErrorsMissingOnly;
    pet_date_of_birth: {
      error_messages: {
        missing: string;
        date_in_future: string;
        too_young: string;
        invalid_day: string;
        invalid_month: string;
        invalid_date: string;
        year_too_short: string;
        old_pet: string;
      };
      warning_messages: {
        young_pet: string;
      };
    };
  };
  csPetAboutYourPetCatQuestionsV2: {
    cat_breed_type: CsErrorsMissingOnly;
    cat_breed_name_pedigree: CsErrorsMissingOnly;
    cat_breed_name_non_pedigree: CsErrorsMissingOnly;
    eligibility_conditions_agreement: CsErrorsMissingOnly;
  };
  csPetAboutYourPetDogQuestionsV2: {
    dog_breed_type: CsErrorsMissingOnly;
    pedigree_dog_breed_name: CsErrorsMissingOnly;
    cross_breed_dog_breed_name: CsErrorsMissingOnly;
    mongrel_size: CsErrorsMissingOnly;
    eligibility_conditions_agreement: CsErrorsMissingOnly;
  };
  csPetAggregatorsV2: {
    assumptions: {
      good_health: {
        warning: string;
      };
      no_complaints_about_behaviour: {
        warning: string;
      };
      not_involved_in_legal_action: {
        warning: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetAboutYourPetMainQuestionsV2 {
      pet_name {
        error_messages {
          missing
        }
      }
      pet_type {
        error_messages {
          missing
        }
      }
      pet_gender {
        error_messages {
          missing
        }
      }
      pet_cost {
        error_messages {
          missing
        }
      }
      pet_spayed {
        error_messages {
          missing
        }
      }
      pet_chipped {
        error_messages {
          missing
        }
      }
      pet_in_good_health {
        error_messages {
          missing
        }
      }
      pet_date_of_birth {
        error_messages {
          missing
          date_in_future
          too_young
          invalid_day
          invalid_month
          invalid_date
          year_too_short
          old_pet
        }
        warning_messages {
          young_pet
        }
      }
    }
    csPetAboutYourPetCatQuestionsV2 {
      cat_breed_type {
        error_messages {
          missing
        }
      }
      cat_breed_name_pedigree {
        error_messages {
          missing
        }
      }
      cat_breed_name_non_pedigree {
        error_messages {
          missing
        }
      }
      eligibility_conditions_agreement {
        error_messages {
          missing
        }
      }
    }
    csPetAboutYourPetDogQuestionsV2 {
      dog_breed_type {
        error_messages {
          missing
        }
      }
      pedigree_dog_breed_name {
        error_messages {
          missing
        }
      }
      cross_breed_dog_breed_name {
        error_messages {
          missing
        }
      }
      mongrel_size {
        error_messages {
          missing
        }
      }
      eligibility_conditions_agreement {
        error_messages {
          missing
        }
      }
    }
    csPetAggregatorsV2 {
      assumptions {
        good_health {
          warning
        }
        no_complaints_about_behaviour {
          warning
        }
        not_involved_in_legal_action {
          warning
        }
      }
    }
  }
`;

export const usePetRules = (): ValidationErrorAndWarningRules<Pet> => {
  const errorMessages = useStaticQuery<CsAboutYourPetErrorMessages>(query);
  const kickoutMessage = useKickoutMessageText();
  const petIsDog = (data: Pet): boolean => data.petType === petType_DOG;
  const dogIsMongrel = (data: Pet): boolean => data.dogBreedType === dogBreedType_MONGREL;
  const petIsCat = (data: Pet): boolean => data.petType === petType_CAT;

  const dogBreedQuestionsDisplayed = (data: Pet): boolean =>
    petIsDog(data) && petBreedQuestionsDisplayed(data);

  const catBreedQuestionsDisplayed = (data: Pet): boolean =>
    petIsCat(data) && petBreedQuestionsDisplayed(data);

  const disableDateChecks = useDisableDateChecks();

  const assumptions = useAssumptions();
  const [assumptionsAgreement] = useAssumptionsAgreement();
  const { petInfos: currentQuotePetInfos } = useCurrentQuote();

  const petNamesInCurrentQuote = currentQuotePetInfos?.map((pet) => {
    return pet.petName;
  });

  const petExistsOnCurrentQuote = (data: Pet): boolean => {
    if (petNamesInCurrentQuote && petNamesInCurrentQuote.indexOf(data.petName) !== -1) {
      return true;
    }
    return false;
  };

  return {
    errors: {
      petName: [
        required(
          errorMessages.csPetAboutYourPetMainQuestionsV2.pet_name.error_messages.missing
        ),
      ],
      petType: [
        required(
          errorMessages.csPetAboutYourPetMainQuestionsV2.pet_type.error_messages.missing
        ),
      ],
      dogBreedType: validateIf(dogBreedQuestionsDisplayed, [
        required(
          errorMessages.csPetAboutYourPetDogQuestionsV2.dog_breed_type.error_messages
            .missing
        ),
      ]),
      dogPedigreeBreedName: validateIf(dogBreedQuestionsDisplayed, [
        applyRuleIf(
          (data: Pet) => data.dogBreedType === dogBreedType_PEDIGREE,
          required(
            errorMessages.csPetAboutYourPetDogQuestionsV2.pedigree_dog_breed_name
              .error_messages.missing
          )
        ),
      ]),
      dogCrossBreedName: validateIf(dogBreedQuestionsDisplayed, [
        applyRuleIf(
          (data: Pet) => data.dogBreedType === dogBreedType_CROSS_BREED,
          required(
            errorMessages.csPetAboutYourPetDogQuestionsV2.cross_breed_dog_breed_name
              .error_messages.missing
          )
        ),
      ]),
      mongrelSize: validateIf(
        (data) => dogBreedQuestionsDisplayed(data) && dogIsMongrel(data),
        [
          required(
            errorMessages.csPetAboutYourPetDogQuestionsV2.mongrel_size.error_messages
              .missing
          ),
        ]
      ),
      catBreedType: validateIf(catBreedQuestionsDisplayed, [
        required(
          errorMessages.csPetAboutYourPetCatQuestionsV2.cat_breed_type.error_messages
            .missing
        ),
      ]),
      catPedigreeBreedName: validateIf(catBreedQuestionsDisplayed, [
        applyRuleIf(
          (data: Pet) => data.catBreedType === catBreedType_PEDIGREE,
          required(
            errorMessages.csPetAboutYourPetCatQuestionsV2.cat_breed_name_pedigree
              .error_messages.missing
          )
        ),
      ]),
      catNonPedigreeBreedName: validateIf(catBreedQuestionsDisplayed, [
        applyRuleIf(
          (data: Pet) => data.catBreedType === catBreedType_NON_PEDIGREE,
          required(
            errorMessages.csPetAboutYourPetCatQuestionsV2.cat_breed_name_non_pedigree
              .error_messages.missing
          )
        ),
      ]),
      petGender: validateIf(generalPetQuestionsDisplayed, [
        required(
          errorMessages.csPetAboutYourPetMainQuestionsV2.pet_gender.error_messages.missing
        ),
      ]),
      petCost: validateIf(generalPetQuestionsDisplayed, [
        required(
          errorMessages.csPetAboutYourPetMainQuestionsV2.pet_cost.error_messages.missing
        ),
      ]),
      petSpayed: validateIf(generalPetQuestionsDisplayed, [
        applyRuleIf(
          (data: Pet) => data.petGender === gender_F,
          required(
            petNeuteredOrSpayedReplacer(
              gender_F,
              errorMessages.csPetAboutYourPetMainQuestionsV2.pet_spayed.error_messages
                .missing
            )
          )
        ),
        applyRuleIf(
          (data: Pet) => data.petGender === gender_M,
          required(
            petNeuteredOrSpayedReplacer(
              gender_M,
              errorMessages.csPetAboutYourPetMainQuestionsV2.pet_spayed.error_messages
                .missing
            )
          )
        ),
        applyRuleIf(
          (data: Pet) => data.petGender !== gender_M && data.petGender !== gender_F,
          required(
            petNeuteredOrSpayedReplacer(
              '',
              errorMessages.csPetAboutYourPetMainQuestionsV2.pet_spayed.error_messages
                .missing
            )
          )
        ),
      ]),
      petChipped: validateIf(
        (data) => generalPetQuestionsDisplayed(data) && petIsCat(data),
        [
          required(
            errorMessages.csPetAboutYourPetMainQuestionsV2.pet_chipped.error_messages
              .missing
          ),
        ]
      ),
      eligibilityConditionsAgreement: validateIf(eligibilityQuestionsDisplayed, [
        applyRuleIf(
          (data) => petIsDog(data),
          required(
            errorMessages.csPetAboutYourPetDogQuestionsV2.eligibility_conditions_agreement
              .error_messages.missing
          )
        ),
        applyRuleIf(
          (data) => petIsCat(data),
          required(
            errorMessages.csPetAboutYourPetCatQuestionsV2.eligibility_conditions_agreement
              .error_messages.missing
          )
        ),
        {
          validityCondition: (value) => value !== false,
          errorMessage: kickoutMessage,
          onlyValidateAfterSubmission: false,
        },
      ]),
      petInGoodHealth: validateIf(goodHealthQuestionDisplayed, [
        required(
          errorMessages.csPetAboutYourPetMainQuestionsV2.pet_in_good_health.error_messages
            .missing
        ),
        {
          validityCondition: (value) => value !== false,
          errorMessage: kickoutMessage,
          onlyValidateAfterSubmission: false,
        },
      ]),
      petDob: validateIf(generalPetQuestionsDisplayed, [
        requiredDateValue(
          errorMessages.csPetAboutYourPetMainQuestionsV2.pet_date_of_birth.error_messages
            .missing
        ),
        dateValueValidDay(
          errorMessages.csPetAboutYourPetMainQuestionsV2.pet_date_of_birth.error_messages
            .invalid_day
        ),
        dateValueValidMonth(
          errorMessages.csPetAboutYourPetMainQuestionsV2.pet_date_of_birth.error_messages
            .invalid_month
        ),
        dateValueValidYear(
          errorMessages.csPetAboutYourPetMainQuestionsV2.pet_date_of_birth.error_messages
            .year_too_short
        ),
        dateValueValid(
          errorMessages.csPetAboutYourPetMainQuestionsV2.pet_date_of_birth.error_messages
            .invalid_date
        ),
        applyRuleIf(
          () => !disableDateChecks,
          dateValueLessThanOrEqualTo(
            new Date(),
            errorMessages.csPetAboutYourPetMainQuestionsV2.pet_date_of_birth
              .error_messages.date_in_future
          )
        ),
        applyRuleIf(
          () => !disableDateChecks,
          dateValueLessThanOrEqualTo(
            /* Since the API won't accept cover start days greater than 30 days from now (UTC) we need to continue showing
            the error until the local date catches up to UTC. The rule also needs to be consistent with the max cover start date. 
            The pet's dob must be <= the latest cover start date possible - 56 days, since it must be atleast 8wks old when cover starts. 
            As the latest cover start date possible is 30days after today, this is the same as saying dob <= today - 26days */
            addDaysToDate(new Date(), -26),
            errorMessages.csPetAboutYourPetMainQuestionsV2.pet_date_of_birth
              .error_messages.too_young
          )
        ),
        applyRuleIf(() => !disableDateChecks, {
          validityCondition: (value, data) =>
            isOlderThanXYearsOld(dateValueToUtcDate(value), petIsDog(data) ? 20 : 25),
          errorMessage:
            errorMessages.csPetAboutYourPetMainQuestionsV2.pet_date_of_birth
              .error_messages.old_pet,
        }),
      ]),
    },
    warnings: {
      petDob: [
        applyRuleIf(
          () => !disableDateChecks,
          dateValueLessThanOrEqualTo(
            /* The API compares the cover start date (UTC) to pet DOB (UTC) so we can compare from midnight UTC, rather than
          the current timezone (i.e. BST, if applicable) */
            addDaysToDate(localDateToUtcDate(new Date()), -56),
            errorMessages.csPetAboutYourPetMainQuestionsV2.pet_date_of_birth
              .warning_messages.young_pet
          )
        ),
      ],
      petInGoodHealth: validateIf(petExistsOnCurrentQuote, [
        applyRuleIf(
          () =>
            !assumptionsAgreement.assumptionsAgreed &&
            assumptionsIncludeId(assumptions, 'good_health'),
          {
            validityCondition: (value) => value !== true,
            errorMessage:
              errorMessages.csPetAggregatorsV2.assumptions.good_health.warning,
          }
        ),
      ]),
    },
  };
};

/*  We need to validate the rules for each pet in PetDetails individually. Therefore, we pass in the rules for each property in Pet,
    and apply them for each pet in PetsDetails in turn.
*/
const processPetRules = <T>(
  rules?: ValidationRule<T, Pet>[]
): ValidationRule<T, { petsDetails: PetsDetails }>[] | undefined =>
  rules?.map(({ validityCondition, ...rest }) => ({
    ...rest,
    validityCondition: (v, data, index) => validityCondition(v, data.petsDetails[index]),
  }));

const useAboutYourPetRules = (): ValidationErrorAndWarningRules<{
  petsDetails: PetsDetails;
}> => {
  const petRules = usePetRules();

  return {
    errors: {
      petsDetails: {
        petName: processPetRules(petRules.errors.petName),
        petType: processPetRules(petRules.errors.petType),
        dogBreedType: processPetRules(petRules.errors.dogBreedType),
        dogPedigreeBreedName: processPetRules(petRules.errors.dogPedigreeBreedName),
        dogCrossBreedName: processPetRules(petRules.errors.dogCrossBreedName),
        mongrelSize: processPetRules(petRules.errors.mongrelSize),
        catBreedType: processPetRules(petRules.errors.catBreedType),
        catPedigreeBreedName: processPetRules(petRules.errors.catPedigreeBreedName),
        catNonPedigreeBreedName: processPetRules(petRules.errors.catNonPedigreeBreedName),
        petGender: processPetRules(petRules.errors.petGender),
        petCost: processPetRules(petRules.errors.petCost),
        petSpayed: processPetRules(petRules.errors.petSpayed),
        petChipped: processPetRules(petRules.errors.petChipped),
        eligibilityConditionsAgreement: processPetRules(
          petRules.errors.eligibilityConditionsAgreement
        ),
        petInGoodHealth: processPetRules(petRules.errors.petInGoodHealth),
        petDob: processPetRules(petRules.errors.petDob),
      },
    },
    warnings: {
      petsDetails: {
        petDob: processPetRules(petRules.warnings.petDob),
        petInGoodHealth: processPetRules(petRules.warnings.petInGoodHealth),
      },
    },
  };
};

export default useAboutYourPetRules;
