import { useLocation } from '@reach/router';
import {
  getDifferenceInDays,
  parseDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { useEffect, useState, version } from 'react';
import { useSelector } from 'react-redux';
import { getDeepLinkParams } from 'pages/pet/quote-generating';
import { RootState } from 'state/createStore';
import {
  AdditionalQuestionsShown,
  useAdditionalQuestionsShown,
} from 'state/formData/additionalQuestionsShown';
import { ProductType } from 'state/formData/quoteOptions';
import useReferenceData from 'state/referenceData/useReferenceData';
import { ReferenceDataOption } from 'types/referenceData';
import { getFullYears } from './ageHelpers';
import {
  getAggregatorFromDeepLinkParams,
  getAggregatorFromProductId,
  getAggregatorFromReferrer,
  getCoverTypeLabelFromQuoteOptions,
  getPath,
  getPetBreedInReadableForm,
  getReadableCoPaymentExcessAmount,
  getReadableExcessAmount,
  getReadablePetBreedType,
  PageTrackingEventProps,
  trackEvent,
  TrackingEvent,
  userHasSelectedCover,
} from './eventTracking';
import { petType_DOG } from './referenceDataConstants';
import {
  CONFUSED_DEEPLINK_REF_VALUE_SESSION_KEY,
  retrieveData,
} from './sessionStorageHelpers';
import {
  CurrentQuote,
  getSelectedProductPrices,
  useCurrentQuote,
} from './useCurrentQuote';
import { useMetaTitle } from './useMetaTitle';

export const getGeneralPageTrackingData = (
  quote: CurrentQuote | null,
  catBreedsRefData: ReferenceDataOption[],
  dogBreedsRefData: ReferenceDataOption[],
  productType?: ProductType,
  paymentMethod?: 'Annual' | 'Monthly'
): PageTrackingEventProps => {
  if (!quote) {
    return {};
  }

  const getAggregatorId = (): string | undefined => {
    const aggregatorIdFromProductId = getAggregatorFromProductId(quote?.productId);
    const aggregatorIdFromReferrer = getAggregatorFromReferrer(document?.referrer);

    return aggregatorIdFromProductId ?? aggregatorIdFromReferrer;
  };

  const pageTrackingData: PageTrackingEventProps = {
    aggregatorQuoteId: getAggregatorId(),
    daysUntilStartDate: quote.policyInfo
      ? getDifferenceInDays(new Date(), parseDate(quote.policyInfo.coverStartDate))
      : undefined,
    journeyType: 'Quote and Buy',
    policyStartDate: quote.policyInfo?.coverStartDate,
    quoteId: quote.policyInfo?.quoteNumber || undefined,
    productName: productType ?? getCoverTypeLabelFromQuoteOptions(quote.quoteOptions),
  };

  if (userHasSelectedCover(quote)) {
    pageTrackingData.paymentMethod =
      paymentMethod || (quote.quoteOptions?.isAnnualPayment ? 'Annual' : 'Monthly');
  }

  if (quote.petInfos) {
    pageTrackingData.breed = quote.petInfos
      .map((petInfo) =>
        getPetBreedInReadableForm(petInfo, catBreedsRefData, dogBreedsRefData)
      )
      .join(',');
    pageTrackingData.breedType = quote.petInfos
      .map((petInfo) => getReadablePetBreedType(petInfo.petBreedType))
      .join(',');
    pageTrackingData.numberOfPets = `${quote.petInfos.length}`;
    pageTrackingData.petType = quote.petInfos
      .map((petInfo) => (petInfo.petType === petType_DOG ? 'Dog' : 'Cat'))
      .join(',');
    pageTrackingData.excess = quote.petInfos
      .map((petInfo) => getReadableExcessAmount(petInfo.voluntaryExcessAmount))
      .join(',');
    pageTrackingData.excessCoPayment = quote.petInfos
      .map((petInfo) =>
        getReadableCoPaymentExcessAmount(petInfo.voluntaryExcessPercentage)
      )
      .join(',');
  }

  return pageTrackingData;
};

export const trackPageView = (
  path: string,
  pageTitle: string,
  quote: CurrentQuote,
  catBreedsRefData: ReferenceDataOption[],
  dogBreedsRefData: ReferenceDataOption[],
  productType?: ProductType,
  paymentMethod?: 'Annual' | 'Monthly',
  additionalQuestionsShown?: AdditionalQuestionsShown
): void => {
  const getAdditionalQuestionsDisplayed = (): string | undefined => {
    const ageString = additionalQuestionsShown?.ageQuestion
      ? `Dob: ${additionalQuestionsShown?.ageQuestion
          ?.map((pet) => `Pet ${pet}`)
          .join(', ')}; `
      : undefined;
    const breedString = additionalQuestionsShown?.breedQuestion
      ? `Breed: ${additionalQuestionsShown?.breedQuestion
          ?.map((pet) => `Pet ${pet}`)
          .join(', ')}`
      : undefined;

    return !!ageString || !!breedString
      ? [ageString, breedString].filter((string) => !!string).join()
      : undefined;
  };

  const getPetAge = (currentQuote: CurrentQuote): string | undefined => {
    if (currentQuote?.petInfos?.length) {
      const firstPet = currentQuote.petInfos[0];
      const petDobDate = parseDate(firstPet.dob);
      const petAge = getFullYears(petDobDate).toString();
      return petAge;
    }
    return undefined;
  };

  const event: TrackingEvent = {
    event: 'newPage',
    vpvPath: getPath(path, quote.quoteOptions),
    pageTitle,
    siteVersion: version,
    additionalQuestionsDisplayed: getAdditionalQuestionsDisplayed(),
    ...getGeneralPageTrackingData(
      quote,
      catBreedsRefData,
      dogBreedsRefData,
      productType,
      paymentMethod
    ),
    // Explicitly flush other variables
    promoCode: quote?.policyInfo?.promotionalCode,
    eventCategory: undefined,
    eventAction: undefined,
    eventLabel: undefined,
    isException: undefined,
  };

  if (userHasSelectedCover(quote)) {
    event.productName = getCoverTypeLabelFromQuoteOptions(quote.quoteOptions);
    event.quotedPremium = getSelectedProductPrices(quote)?.annualPrice?.total?.toString();
  }

  if (quote.quoteOptions.partnershipCard !== undefined) {
    event.partnershipCard = quote.quoteOptions.partnershipCard ? 'yes' : 'no';
  }

  if (event?.aggregatorQuoteId === undefined) {
    let aggId = getAggregatorFromReferrer(document.referrer);
    if (!aggId) {
      const deepLinkParams = getDeepLinkParams(window.location);
      aggId = getAggregatorFromDeepLinkParams(deepLinkParams);
    }
    event.aggregatorQuoteId = aggId;
  }

  if (quote.petInfos) {
    event.petAge = getPetAge(quote);
  }

  if (event?.aggregatorQuoteId === 'Confused') {
    const confusedRefSessionKey = retrieveData(CONFUSED_DEEPLINK_REF_VALUE_SESSION_KEY);
    const deepLinkParams = getDeepLinkParams(window.location);
    event.confusedDeeplinkRefValue = deepLinkParams?.confusedRef
      ? deepLinkParams?.confusedRef
      : confusedRefSessionKey;
  }

  trackEvent(event);
};

// Hook to track once when the quote loads
export const usePageTracking = (
  metaTitle: string,
  pageLoaded = true,
  quoteToTrack?: CurrentQuote | null
): void => {
  const [tracked, setTracked] = useState(false);
  const { pathname } = useLocation();
  const getFullMetaTitle = useMetaTitle();
  const error = useSelector((state: RootState) => state.error);
  const currentQuote = useCurrentQuote();
  const [additionalQuestionsShown] = useAdditionalQuestionsShown();

  const EMPTY: never[] = [];

  const catBreedsRefData = useReferenceData('catBreeds')?.catBreeds ?? EMPTY;
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? EMPTY;

  useEffect(() => {
    if (!tracked && !error.errorType && metaTitle && pageLoaded) {
      trackPageView(
        pathname,
        getFullMetaTitle(metaTitle),
        quoteToTrack || currentQuote,
        catBreedsRefData,
        dogBreedsRefData,
        undefined,
        undefined,
        additionalQuestionsShown
      );
      setTracked(true);
    }
  }, [
    error.errorType,
    getFullMetaTitle,
    metaTitle,
    pageLoaded,
    pathname,
    tracked,
    catBreedsRefData,
    dogBreedsRefData,
    quoteToTrack,
    currentQuote,
    additionalQuestionsShown,
  ]);
};
