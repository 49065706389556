import { AxiosAPIError } from './types';

const CUSTOMER_INELIGIBLE_ERROR_CODE = 'EVO_BUS_P170';
const POLICY_BLOCKED_ERROR_CODE = 'EVO_BUS_P171';

const ineligibleQuoteErrorCodes = [
  CUSTOMER_INELIGIBLE_ERROR_CODE,
  POLICY_BLOCKED_ERROR_CODE,
];

export const isIneligibleQuoteError = (error: AxiosAPIError): boolean =>
  error.response?.status === 500 &&
  ineligibleQuoteErrorCodes.some(
    (ineligibleErrorCode) => ineligibleErrorCode === error.response?.data?.Code
  );
