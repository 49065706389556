import flatMap from 'lodash/flatMap';
import { Bundle, Quote } from 'apiHelpers/quote/quoteResponse';
import {
  CORE_COVER,
  CoverLevel,
  CoverType,
  VetBillsAccidentsAndIllness,
} from 'helpers/businessConstants';
import {
  initialQuoteOptions,
  ProductType,
  QuoteOptions,
} from 'state/formData/quoteOptions';
import { QuoteCover, RequoteParams } from './quoteRequest';

export const hasUserSelectedCover = (quote: Quote): boolean =>
  !!quote.petInfos[0]?.userSelectedCover;

export const getProductTypeFromCoverLevel = (
  coverLevel: CoverLevel | undefined
): ProductType | undefined => {
  switch (coverLevel) {
    case CoverLevel.VET_FEE_LIMIT_2000:
      return ProductType.Vet_Fee_Limit_2000;
    case CoverLevel.VET_FEE_LIMIT_3000:
      return ProductType.Vet_Fee_Limit_3000;
    case CoverLevel.VET_FEE_LIMIT_5000:
      return ProductType.Vet_Fee_Limit_5000;
    case CoverLevel.VET_FEE_LIMIT_7500:
      return ProductType.Vet_Fee_Limit_7500;
    case CoverLevel.VET_FEE_LIMIT_9000:
      return ProductType.Vet_Fee_Limit_9000;
    case CoverLevel.VET_FEE_LIMIT_12000:
      return ProductType.Vet_Fee_Limit_12000;
    default:
      return undefined;
  }
};

export const getQuoteOptionsFromQuote = (quote: Quote): QuoteOptions => {
  if (!hasUserSelectedCover(quote)) {
    return initialQuoteOptions;
  }
  const coverLevel = quote.petInfos[0].coverLevelRequired;
  return {
    ...initialQuoteOptions,
    productType: getProductTypeFromCoverLevel(coverLevel),
  };
};

/**
 * Gets the bundle on the quote associated with the cover level supplied.
 * Note that this assumes there is only one version of each bundle in the quote.
 *
 * @param coverLevel The cover level to find the bundle for
 * @param quote The quote, as returned from the API
 * @returns The bundle associated with the supplied cover level
 */
export const getBundleFromQuote = (
  coverLevel: CoverLevel | undefined,
  quote: Quote
): Bundle | undefined => {
  if (!coverLevel) {
    return undefined;
  }

  const allBundles = flatMap(
    quote.premium.bundleContainers,
    (container) => container.bundles
  );

  return allBundles.find((bundle) => bundle.coverLevelRequired === coverLevel);
};

export const getCoverLevelFromQuoteOptions = (
  quoteOptions: QuoteOptions
): CoverLevel | undefined => {
  const { productType } = quoteOptions;

  switch (productType) {
    case ProductType.Vet_Fee_Limit_2000:
      return CoverLevel.VET_FEE_LIMIT_2000;
    case ProductType.Vet_Fee_Limit_3000:
      return CoverLevel.VET_FEE_LIMIT_3000;
    case ProductType.Vet_Fee_Limit_5000:
      return CoverLevel.VET_FEE_LIMIT_5000;
    case ProductType.Vet_Fee_Limit_7500:
      return CoverLevel.VET_FEE_LIMIT_7500;
    case ProductType.Vet_Fee_Limit_9000:
      return CoverLevel.VET_FEE_LIMIT_9000;
    case ProductType.Vet_Fee_Limit_12000:
      return CoverLevel.VET_FEE_LIMIT_12000;
    default:
      return undefined;
  }
};

/**
 * @param quote The quote being updated
 * @param coverLevel The cover level selected
 * @param coverStartDate (Optional) the start date for the cover, if different to the quote cover start date
 * @returns Array of covers to send for requoting
 */
const getQuoteCoversForCoverLevel = (
  quote: Quote,
  coverLevel: CoverLevel,
  coverStartDate?: string
): QuoteCover[] => {
  const selectedBundle = getBundleFromQuote(coverLevel, quote);

  /* istanbul ignore if */
  if (!selectedBundle) {
    return [];
  }

  const selectedQuoteCovers = quote.covers.map((quoteCover) => ({
    ...quoteCover,
    coverIncludedIndicator:
      !!selectedBundle.covers.find(
        (bundleCover) => bundleCover.coverCode === quoteCover.coverSection
      ) || quoteCover.coreCoverIndicator,
    // Cover start dates need to be supplied as an ISO string (i.e. with time component) so we add
    // that here manually if using the start date on the quote. We could also use the date on the
    // previous quoteCover (it should match), but doing it this way ensures it's definitely correct
    // and assumes less of the API! (TODO:EP-199)
    coverStartDate: coverStartDate ?? `${quote.policyInfo.coverStartDate}T00:00:00`,
  }));

  return selectedQuoteCovers;
};

export const getCoverLevelFromQuote = (quote: Quote): CoverLevel | undefined => {
  const coverLevel = quote.petInfos[0].coverLevelRequired;
  // TODO: EP-255: Remove this function and inline quote.petInfos[0].coverLevelRequired where used
  return coverLevel || CoverLevel.VET_FEE_LIMIT_2000;
};

export const isQuoteOptionSelectionValid = (quoteOptions: QuoteOptions): boolean =>
  !!quoteOptions.productType;

/**
 * @param quote The quote being updated
 * @param quoteOptions The options the user has selected
 * @param coverStartDate (Optional) the start date for the cover, if different to the quote cover start date*
 * @returns Additional parameters required for the requote request
 *
 * *The start date on the cover objects needs to match the policy start date; the backend does
 * not handle this sensibly by default. In the backend/API sometimes start dates for individual
 * covers are used instead of the actual policy start date, so it's extra important these are in
 * sync!
 */
export const generateRequoteParameters = (
  quote: Quote,
  quoteOptions: QuoteOptions,
  requestCoverLevel: CoverLevel,
  coverStartDate?: string
): RequoteParams => {
  return {
    productId: quote.productId,
    covers: getQuoteCoversForCoverLevel(quote, requestCoverLevel, coverStartDate),
    isUserSelectedCover: isQuoteOptionSelectionValid(quoteOptions),
    vetBillsAccidentsOnly: CoverType.ACCIDENT_AND_ILLNESS,
    vetBillsAccidentsAndIllness: VetBillsAccidentsAndIllness.ONGOING,
  };
};

/**
 * Gets selected covers that are included in the selected bundle for the quote, excluding the core cover.
 *
 * @param quote
 * @returns array of cover codes included in the quote
 */
export const getSelectedBundleCovers = (quote: Quote): string[] => {
  const coverLevel = getCoverLevelFromQuote(quote);
  const bundle = getBundleFromQuote(coverLevel, quote);

  if (!bundle) {
    return [];
  }

  return flatMap(quote.parentCover, (parentCoverCode) =>
    bundle.covers
      .filter(
        (cover) =>
          parentCoverCode !== CORE_COVER &&
          (cover.coverCode === parentCoverCode || cover.cover.name === parentCoverCode) &&
          cover.selected
      )
      .map((cover) => cover.coverCode)
  );
};
