import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import quoteClient from 'apiHelpers/quoteClient';
import {
  CoverLevel,
  VoluntaryExcessAmount,
  VoluntaryExcessPercentage,
} from 'helpers/businessConstants';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useDisableDateChecks from 'helpers/useDisableDateChecks';
import useLoadingState from 'helpers/useLoadingState';
import { RootState } from 'state/createStore';
import { useCustomerDetails } from 'state/formData/customerDetails';
import { usePolicyDetails } from 'state/formData/policyDetails';
import { UPDATE_QUOTE, UpdateQuoteAction } from 'state/quote/quote';
import {
  generateRequoteParameters,
  getCoverLevelFromQuoteOptions,
} from './bundleCoverMapping';
import {
  getMarketingCommunication,
  mapMarketingConsents,
} from './formToQuoteMappings/mapPolicyInfo';
import { QuoteRequestPetInfo, RequoteRequest } from './quoteRequest';
import { mapMarketingConsent } from './quoteToFormMappings/mapPolicyDetails';

export type QuoteUpdaters = {
  saveQuoteOptions: (triggerEmail?: boolean) => Promise<void>;
  updatePromoCode: (promoCode: string) => Promise<void>;
  updatePetExcess: (
    petIndex: number,
    updatedExcess: VoluntaryExcessAmount
  ) => Promise<void>;
  updatePetExcessPercentage: (
    petIndex: number,
    updatedExcess: VoluntaryExcessPercentage
  ) => Promise<void>;
  isLoading: boolean;
  updatePetVoluntaryExcessAmounts: (
    updatedExcesses: VoluntaryExcessAmount[]
  ) => Promise<void>;
} | null;

const useQuoteUpdaters = (): QuoteUpdaters => {
  const storedQuote = useSelector((state: RootState) => state.quote);
  const quoteOptions = useSelector((state: RootState) => state.quoteSummaryOptions);
  const dispatch = useDispatch<Dispatch<UpdateQuoteAction>>();
  const { isLoading, withLoadingState } = useLoadingState();
  const currentQuote = useCurrentQuote();
  const [customerDetails] = useCustomerDetails();
  const [policyDetails] = usePolicyDetails();
  const disableDateChecks = useDisableDateChecks();

  if (!storedQuote) {
    return null;
  }

  const currentCoverLevelRequired = getCoverLevelFromQuoteOptions(quoteOptions);

  /* istanbul ignore next */
  const marketingConsentsHaveBeenUpdated = (): boolean => {
    const oldMarketingCommunication = currentQuote.policyInfo?.marketingCommunication;
    const oldMarketingConsents = oldMarketingCommunication?.marketingConsent
      ? mapMarketingConsent(oldMarketingCommunication?.marketingConsent)
      : undefined;

    return (
      oldMarketingCommunication?.marketingConsentFlag !==
        policyDetails.marketingConsentFlag ||
      oldMarketingConsents?.johnLewis !== policyDetails.marketingConsents.johnLewis ||
      oldMarketingConsents?.johnLewisFinancialServices !==
        policyDetails.marketingConsents.johnLewisFinancialServices ||
      oldMarketingConsents?.waitrose !== policyDetails.marketingConsents.waitrose
    );
  };

  const contactDetailsHaveBeenUpdated = (): boolean => {
    const oldContactEmail = currentQuote.customerInfo?.email;
    const oldContactTelephone = currentQuote.customerInfo?.contactPhoneNumber;
    return (
      oldContactEmail !== customerDetails.customerEmail ||
      oldContactTelephone !== customerDetails.customerTelephone
    );
  };

  const isUpdateRequired = (triggerEmail: boolean): boolean =>
    triggerEmail ||
    storedQuote.petInfos.some(
      (pet) => pet.coverLevelRequired !== currentCoverLevelRequired
    ) ||
    marketingConsentsHaveBeenUpdated() ||
    contactDetailsHaveBeenUpdated();

  const buildSaveQuoteOptionsRequest = (triggerEmail?: boolean): RequoteRequest => ({
    ...storedQuote,
    customerInfo: {
      ...storedQuote.customerInfo,
      email: customerDetails.customerEmail,
      contactPhoneNumber: customerDetails.customerTelephone,
    },
    policyInfo: {
      ...storedQuote.policyInfo,
      marketingCommunication: getMarketingCommunication({
        marketingConsent: mapMarketingConsents(policyDetails.marketingConsents),
        marketingConsentFlag: policyDetails.marketingConsentFlag,
      }),
      disableDateChecks,
    },
    coverLevelRequired: currentCoverLevelRequired || CoverLevel.VET_FEE_LIMIT_2000,
    ...generateRequoteParameters(
      storedQuote,
      quoteOptions,
      currentCoverLevelRequired || CoverLevel.VET_FEE_LIMIT_2000
    ),
    triggerCorrespondenceService: triggerEmail,
  });

  const performRequest = async (request: RequoteRequest): Promise<void> => {
    const quote = await withLoadingState(() => quoteClient.requote(request));
    dispatch({ type: UPDATE_QUOTE, quote });
  };

  const saveQuoteOptions = async (triggerEmail?: boolean): Promise<void> => {
    if (isUpdateRequired(!!triggerEmail)) {
      const request = buildSaveQuoteOptionsRequest(triggerEmail);
      await performRequest(request);
    }
  };

  const buildUpdatePetInfosRequest = (
    updatedPetInfos: QuoteRequestPetInfo[]
  ): RequoteRequest => ({
    ...storedQuote,
    policyInfo: { ...storedQuote.policyInfo, disableDateChecks },
    ...generateRequoteParameters(
      storedQuote,
      quoteOptions,
      currentCoverLevelRequired || CoverLevel.VET_FEE_LIMIT_2000
    ),
    petInfos: updatedPetInfos,
    coverLevelRequired: currentCoverLevelRequired || CoverLevel.VET_FEE_LIMIT_2000,
  });

  const buildUpdatePromoCodeRequest = (promoCode: string): RequoteRequest => ({
    ...storedQuote,
    policyInfo: {
      ...storedQuote.policyInfo,
      promotionalCode: promoCode,
      disableDateChecks,
    },
    ...generateRequoteParameters(
      storedQuote,
      quoteOptions,
      currentCoverLevelRequired || CoverLevel.VET_FEE_LIMIT_2000
    ),
    coverLevelRequired: currentCoverLevelRequired || CoverLevel.VET_FEE_LIMIT_2000,
  });

  const updatePromoCode = async (promoCode: string): Promise<void> => {
    const request = buildUpdatePromoCodeRequest(promoCode);
    await performRequest(request);
  };

  const updatePetExcess = async (
    petIndex: number,
    updatedExcess: VoluntaryExcessAmount
  ): Promise<void> => {
    const updatedPetInfos = storedQuote.petInfos.map((pet, index) => ({
      ...pet,
      voluntaryExcessAmount:
        index === petIndex ? updatedExcess : pet.voluntaryExcessAmount,
    }));
    const request = buildUpdatePetInfosRequest(updatedPetInfos);
    await performRequest(request);
  };

  const updatePetExcessPercentage = async (
    petIndex: number,
    updatedExcess: VoluntaryExcessPercentage
  ): Promise<void> => {
    const updatedPetInfos = storedQuote.petInfos.map((pet, index) => ({
      ...pet,
      voluntaryExcessPercentage:
        index === petIndex ? updatedExcess : pet.voluntaryExcessPercentage,
    }));
    const request = buildUpdatePetInfosRequest(updatedPetInfos);
    await performRequest(request);
  };

  const updatePetVoluntaryExcessAmounts = async (
    updatedPetExcess: VoluntaryExcessAmount[]
  ): Promise<void> => {
    const updatedPetInfos = storedQuote.petInfos.map((pet, index) => ({
      ...pet,
      voluntaryExcessAmount: updatedPetExcess[index],
    }));
    const request = buildUpdatePetInfosRequest(updatedPetInfos);
    await performRequest(request);
  };

  return {
    saveQuoteOptions,
    updatePromoCode,
    updatePetExcess,
    updatePetExcessPercentage,
    updatePetVoluntaryExcessAmounts,
    isLoading,
  };
};

export default useQuoteUpdaters;
