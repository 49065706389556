import React from 'react';
import { PageTitle } from 'helpers/eventTracking';
import DefaultSessionExpiryWrapper from './DefaultSessionExpiryWrapper';

export enum SessionExpiryOption {
  NO_SESSION_EXPIRY = 'NO_SESSION_EXPIRY',
  DEFAULT_SESSION_EXPIRY = 'DEFAULT_SESSION_EXPIRY',
  EMAIL_QUOTE_SESSION_EXPIRY = 'EMAIL_QUOTE_SESSION_EXPIRY',
}

type SessionExpiryWrapperProps = {
  pageTitle: PageTitle;
  sessionExpiryOption: SessionExpiryOption;
  children?: React.ReactNode;
};

const SessionExpiryWrapper: React.FC<SessionExpiryWrapperProps> = ({
  pageTitle,
  sessionExpiryOption,
  children,
}) => {
  const showEmailQuoteCta =
    sessionExpiryOption === SessionExpiryOption.EMAIL_QUOTE_SESSION_EXPIRY;

  switch (sessionExpiryOption) {
    case SessionExpiryOption.NO_SESSION_EXPIRY:
      return <>{children}</>;
    case SessionExpiryOption.DEFAULT_SESSION_EXPIRY:
    case SessionExpiryOption.EMAIL_QUOTE_SESSION_EXPIRY:
    default:
      return (
        <DefaultSessionExpiryWrapper
          pageTitle={pageTitle}
          showEmailQuoteCta={showEmailQuoteCta}>
          {children}
        </DefaultSessionExpiryWrapper>
      );
  }
};

export default SessionExpiryWrapper;
