import { AxiosAPIError, ErrorDetails } from 'businessLogic/errors/types';

const createAxiosError = (status?: number, data?: ErrorDetails): AxiosAPIError => {
  const error: AxiosAPIError = {
    ...new Error(),
    isAxiosError: true,
    config: {},
    toJSON: () => ({}),
    cause: undefined,
  };
  return status
    ? {
        ...error,
        response: {
          status,
          data: data || null,
          statusText: '',
          config: {},
          headers: {},
        },
      }
    : error;
};

export default createAxiosError;
